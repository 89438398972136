import { Suspense } from "react";
import { Route } from "react-router-dom";
import { Spinner } from "@components/Spinner";
import NotFound from "./NotFound";
import { LocalizedSwitch } from "@components/I18n/LocalizedSwitch";
import { useSelector } from "react-redux";
import AccountNotVerified from "@components/AccountNotVerified/AccountNotVerified";

const AnonymousDashboardRoutes = ({ applicationRoutes }) => {

  return (
    <Suspense fallback={<Spinner />}>
      <LocalizedSwitch>
        {applicationRoutes.anonymousDashboard.map((route) => (
          <Route {...route} key={route.path} />
        ))}
        <Route component={NotFound} />
      </LocalizedSwitch>
    </Suspense>
  );
};

export default AnonymousDashboardRoutes;
